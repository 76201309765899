import {
  tableState,
  tableMutations,
  tableActions
} from "@tt/vue-components"

import allocationService from "@/services/AllocationService";

const requestItems = {
  namespaced: true,
  state: {
    ...tableState,
    loading: false,
  },
  mutations: {
    ...tableMutations,
  },
  actions: {
    ...tableActions,
    fetchItems(context, orderId, refresh) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = {
          pagination: { page: 1, itemsPerPage: 999},
          sorting: { sortBy: [], sortDesc: [] },
          search: [{ column: "request.reference", value: orderId }]
        };
        allocationService.requestItems.list(params)
          .then(json => {
            context.commit("SET_ITEMS", json);
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      }
    },
    resetItems(context) {
      context.commit("SET_ITEMS", { items: [], totalItems: 0 } );
    }
  }
};

export default requestItems;
