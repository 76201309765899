import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";

import channelFilter from "@/store/modules/ordersOverview/filter/channelFilter";
import commentStatusFilter from "@/store/modules/ordersOverview/filter/commentStatusFilter";
import orderNoFilter from "@/store/modules/ordersOverview/filter/orderNoFilter";
import orderDateFilter from "@/store/modules/ordersOverview/filter/orderDateFilter";
import emailFilter from "@/store/modules/ordersOverview/filter/emailFilter";
import shippingMethodFilter from "@/store/modules/ordersOverview/filter/shippingMethodFilter";
import shippingStatusFilter from "@/store/modules/ordersOverview/filter/shippingStatusFilter";
import order from "@/store/modules/ordersOverview/order";
import shipments from "@/store/modules/ordersOverview/shipments";
import orderReturns from "@/store/modules/ordersOverview/orderReturns";
import orderComments from "@/store/modules/ordersOverview/orderComments";
import requestItems from "@/store/modules/ordersOverview/requestItems";
import orderService from "@/services/OrderService";

const ordersOverview = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
    item: null,
    filters: [],
    defaultFilters: [],
    sorting: { sortBy: ["orderDt"], sortDesc: [true] },
    pagination: { itemsPerPage: 25, itemsPage: 1 },
  },
  modules: {
    commentStatusFilter,
    channelFilter,
    orderNoFilter,
    orderDateFilter,
    emailFilter,
    shippingMethodFilter,
    order,
    shipments,
    orderReturns,
    orderComments,
    requestItems,
    shippingStatusFilter
  },
  mutations: {
    ...tableMutations,
    SET_ITEM(state, item) {
      state.item = item;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = { pagination: context.state.pagination, sorting: context.state.sorting, search: context.state.search };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();
        orderService.orders.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              context.commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      }
    },
    selectItem(context, item) {
      context.commit("SET_ITEM", item);
      context.commit("ordersOverview/order/SET_ITEM", item, {root: true});
      if (item !== null && item !== undefined) {
        context.dispatch("shipments/fetchItems", item.id);
        context.dispatch("requestItems/fetchItems", item.reference);
        context.dispatch("orderReturns/fetchItems", item.id);
      } else {
        context.dispatch("shipments/resetItems");
        context.dispatch("requestItems/resetItems");
        context.dispatch("orderReturns/resetItems");
      }
    },
    // Helper for the child component updates
    updateItem({ commit }, item) {
      commit("EDIT_ITEM", item)
    }
  },
  getters: {
    ...tableGetters,
    openComments: (state) => (order) => {
      return order.comments.filter(comment => comment.status === state.orderComments.openState) ?? []
    }
  }
};

export default ordersOverview;
