import {
  tableState,
  tableMutations,
  tableActions
} from "@tt/vue-components"

import orderService from "@/services/OrderService";

const orderReturns = {
  namespaced: true,
  state: {
    ...tableState,
    loading: false,
  },
  mutations: {
    ...tableMutations,
  },
  actions: {
    ...tableActions,
    fetchItems(context, orderId, refresh) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = {
          sorting: { sortBy: ["createDt"], sortDesc: [true] }
        };

        orderService.orders.getSubResource(orderId, 'returns', params)
          .then(json => {
            context.commit("SET_ITEMS", json);
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      }
    },
    resetItems(context) {
      context.commit("SET_ITEMS", { items: [], totalItems: 0 } );
    }
  }
};

export default orderReturns;
